/* eslint-disable import/no-unresolved */
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { Helmet } from 'react-helmet-async'

// Language
// eslint-disable-next-line import/extensions
import { getLanguage } from 'services/language'

// Icon
import favicon from 'img/favicon.png'

const query = graphql`
  {
    site {
      siteMetadata {
        pathPrefix
        title
        description
        url
      }
    }
  }
`

const getYoastMeta = (data) => {
  const response = { title: null, description: null }

  if (data) {
    const yoastTitleObj = data.find(({ property }) => property === 'og:title')

    if (yoastTitleObj) {
      response.title = yoastTitleObj.content
    }

    const yoastDescriptionObj = data.find(({ name }) => name === 'description')

    if (yoastDescriptionObj) {
      response.description = yoastDescriptionObj.content
    }
  }

  return response
}

export const removeHTML = (content) => {
  let newContent = content

  if (typeof document !== 'undefined') {
    const titleHtml = newContent
    const div = document.createElement('div')
    div.innerHTML = titleHtml
    newContent = div.innerText
  }

  return newContent
}

function SEO({
  title,
  description,
  path,
  image,
  type,
  yoast,
  yoast_title,
  page,
  questions,
}) {
  const language = getLanguage()

  if (!yoast) {
    return null
  }

  return (
    <StaticQuery
      query={query}
      render={(queryResponse) => {
        const yoastMeta = getYoastMeta(yoast)

        const seoTitle =
          yoast_title ||
          yoastMeta.title ||
          queryResponse.site.siteMetadata.title ||
          title ||
          ''
        const seoDescription =
          description ||
          yoastMeta.description ||
          queryResponse.site.siteMetadata.description ||
          ''
        const seoPath = `${queryResponse.site.siteMetadata.url}${path || ''}`
        const seoImage = image || {}
        const seoType = type || 'website'

        if (seoImage.url && seoImage.url.indexOf('http') === -1) {
          seoImage.url = `${queryResponse.site.siteMetadata.url.replace(
            `/${queryResponse.site.siteMetadata.pathPrefix}`,
            ''
          )}${seoImage.url}`
        }

        return (
          <>
            <GatsbySeo
              title={removeHTML(seoTitle)}
              description={removeHTML(seoDescription)}
              canonical={seoPath.replace('_', '-')}
              locale={language}
              openGraph={{
                type: seoType,
                url: seoPath,
                title: seoTitle,
                description: seoDescription,
                images: [seoImage],
              }}
            />
            <Helmet>
              <link rel="icon" href={favicon} />
              <script
                id="Cookiebot"
                src="https://consent.cookiebot.com/uc.js"
                data-cbid="80130422-3d0f-4c4b-9706-7b8f43f2ed4f"
                type="text/javascript"
                async
              />
              <script>
                {`
                  (function(l,e,a,d,i,n,f,o){if(!l[i]){
                    l.GlobalLeadinfoNamespace=l.GlobalLeadinfoNamespace || []; 
                    l.GlobalLeadinfoNamespace.push(i);
                    l[i]=function(){
                      (l[i].q=l[i].q||[]).push(arguments)};
                      l[i].t=l[i].t||n;
                      l[i].q=l[i].q||[];o=e.createElement(a);
                      f=e.getElementsByTagName(a)[0];
                      o.async=1;
                      o.src=d;
                      f.parentNode.insertBefore(o,f);
                    }
                  }(window,document,"script","https://cdn.leadinfo.net/ping.js","leadinfo","LI-60487D636ECEE"));
                `}
              </script>
              {page === 'faq' && (
                <script type="application/ld+json">
                  {`
                    {
                      "@context": "https://schema.org",
                      "@type": "FAQPage",
                      "mainEntity": [
                        ${questions.map(
                    ({ question, answer }) => `
                          {
                            "@type": "Question",
                            "name": "${question}",
                            "acceptedAnswer": {
                              "@type": "Answer",
                              "text": "${answer}"
                            }
                          }
                        `
                  )}
                      ]
                    }
                  `}
                </script>
              )}
            </Helmet>
          </>
        )
      }}
    />
  )
}

export default SEO
